
import Bus from '@/util/bus';
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { Moment } from 'moment';
import {
  initCustomerList,
  getCustomerList,
  PaginationData,
  CustomerData,
  CustomerSendData,
  exportData,
} from '@/api/operation/customer';
import bus from '@/util/bus';
import { useMain } from '@/pinia/index';
import { Form as AntForm } from 'ant-design-vue';

const ACCOUNT_STATUS = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '正常',
  },
  {
    id: 2,
    value: '已注销',
  },
];
interface FormData {
  search: string;
  last_consumption_time: Moment[];
  create_time: Moment[];
  order_count_start: string;
  order_count_end: string;
  consumption_amount_start: string | number;
  consumption_amount_end: string | number;
  status: number;
}
function initFormData(): FormData {
  return {
    search: '',
    last_consumption_time: [],
    create_time: [],
    order_count_start: '',
    order_count_end: '',
    consumption_amount_start: '',
    consumption_amount_end: '',
    status: 0,
  };
}

@Component({
  name:'Customer'
})
export default class Customer extends Vue {
  customer_data: PaginationData<CustomerData> = initCustomerList(); // 客户列表数据
  form_data: FormData = initFormData(); // 搜索表单数据
  is_loading: boolean = false;
  searchForm: any;
  // pagination = useMain().pagination;

  async submit(e) {
    e.preventDefault();
    this.searchForm.validateFields(async (err, values) => {
      if (!err) {
        console.log(values);
        this.form_data = {
          ...values,
        };
        this.query();
      }
    });
  }
  get account_status() {
    return ACCOUNT_STATUS;
  }

  paginationData(data: PaginationData<CustomerData>) {
    return {
      current: data.current || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条, 每页${data.limit}条`,
      total: data.total || 0,
    };
  }

  async exportData() {
    const _data = this.form_data;
    const query_params: CustomerSendData = {
      search: _data.search,
      last_consumption_time_start: _data.last_consumption_time[0]
        ? _data.last_consumption_time[0].format('X')
        : '',
      last_consumption_time_end: _data.last_consumption_time[1]
        ? _data.last_consumption_time[1].format('X')
        : '',
      create_time_start: _data.create_time[0] ? _data.create_time[0].format('X') : '',
      create_time_end: _data.create_time[1] ? _data.create_time[1].format('X') : '',
      order_count_start: _data.order_count_start,
      order_count_end: _data.order_count_end,
      consumption_amount_start: +_data.consumption_amount_start * 100,
      consumption_amount_end: +_data.consumption_amount_end * 100,
      status: _data.status === 0 ? '' : _data.status,
      current: this.customer_data.current,
      is_export: 1,
    };
    try {
      const r: any = await exportData(query_params);
      if (r.status === 402) {
        this.$message.error(r.message);
      }
    } catch (error) {
      // window.location.href = `https:${error}`;
    }
  }

  // 点击搜索按钮
  @changeLoading(['is_loading'])
  async query() {
    const _data = this.form_data;
    const send_data: CustomerSendData = {
      search: _data.search,
      last_consumption_time_start: _data.last_consumption_time[0]
        ? _data.last_consumption_time[0].format('X')
        : '',
      last_consumption_time_end: _data.last_consumption_time[1]
        ? _data.last_consumption_time[1].format('X')
        : '',
      create_time_start: _data.create_time[0] ? _data.create_time[0].format('X') : '',
      create_time_end: _data.create_time[1] ? _data.create_time[1].format('X') : '',
      order_count_start: _data.order_count_start,
      order_count_end: _data.order_count_end,
      consumption_amount_start: +_data.consumption_amount_start * 100,
      consumption_amount_end: +_data.consumption_amount_end * 100,
      status: _data.status === 0 ? '' : _data.status,
      current: this.customer_data.current,
    };
    this.getCustomerData(send_data);
  }
  toOrder(record: any) {
    // this.$router.push(`/order/list?user_id=${record.register_id}`);
    bus.$emit('menuIndex', 1);
  }
  // 清空搜索条件
  initForm() {
    this.form_data = initFormData();
    this.searchForm.resetFields();
    this.customer_data.current = 1;
    this.query();
  }

  // 切换分页
  @changeLoading(['is_loading'])
  async handleChange(pagination: any) {
    this.customer_data.current = pagination.current;
    this.query();
  }

  // toOrderPage(item: any) {
  //   this.$router.push({
  //     name: 'OrderList',
  //     params: {
  //       user_id: item.user_id,
  //     },
  //   });
  // }

  // 获取客户列表
  @changeLoading(['is_loading'])
  async getCustomerData(send_data?: CustomerSendData) {
    this.customer_data = initCustomerList();
    this.searchForm = (AntForm as any)?.createForm(this, {
      name: 'form',
    });
    if (!send_data) {
      const obj: any = {};
      obj.current = 1;
      obj.limit = 10;
      send_data = obj;
    }
    // if (this.pagination?.customer) {
      
    //   Object.keys(this.pagination.customer).forEach(item => {
    //     if (item !== 'current') {
    //       this.form_data[item] = this.pagination.customer[item];
    //     } else {
    //       this.customer_data.current = this.pagination.customer[item];
    //     }
    //   });
    //   // await this.getAccountList(this.pagination?.managment);
    //   this.query();
    //   delete this.pagination.customer;
    //   return;
    // }
    const res = await getCustomerList(send_data);
    
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.customer_data = initCustomerList(res.data);
  }
  onLink() {
    // this.pagination.customer = {
    //   ...this.form_data,
    //   current: this.customer_data.current,
    // };
  }
  @changeLoading(['is_loading'])
  async created() {
    await this.getCustomerData();
  }
  //数字输入框change事件
  money_true: boolean = false; //价格提示框显隐属性
  order_number: boolean = false; //剩余库存提示框显隐
  consumption_number: boolean = false; //总销量库存提示框显隐
  onInputNumberChange(value: number, type: string) {
    const ret = /^(-?\d+)(\.\d{1,2})?$/; //正则定义两位小数
    const res = /(^[1-9]\d*$)/; //正整数正则
    if (type == 'order_count_start') {
      //传进来价格参数判断
      if (
        this.form_data.order_count_start != '' &&
        this.form_data.order_count_start != null &&
        (Number(this.form_data.order_count_start) < 0 ||
          !res.test(this.form_data.order_count_start))
      ) {
        this.order_number = true;
      } else {
        this.order_number = false;
      }
      this.form_data.order_count_start = value.toFixed(0);
    } else if (type == 'order_count_end') {
      if (
        this.form_data.order_count_end != '' &&
        this.form_data.order_count_end != null &&
        (Number(this.form_data.order_count_end) < 0 || !res.test(this.form_data.order_count_end))
      ) {
        this.order_number = true;
      } else {
        this.order_number = false;
      }
      this.form_data.order_count_end = value.toFixed(0);
    } else if (type == 'consumption_amount_start') {
      if (
        this.form_data.consumption_amount_start != '' &&
        this.form_data.consumption_amount_start != null &&
        (Number(this.form_data.consumption_amount_start) < 0 ||
          !ret.test(String(this.form_data.consumption_amount_start)))
      ) {
        this.consumption_number = true;
      } else {
        this.consumption_number = false;
      }
      this.form_data.consumption_amount_start = value.toFixed(2);
    } else if (type == 'consumption_amount_end') {
      if (
        this.form_data.consumption_amount_end != '' &&
        this.form_data.consumption_amount_end != null &&
        (Number(this.form_data.consumption_amount_end) < 0 ||
          !ret.test(String(this.form_data.consumption_amount_end)))
      ) {
        this.consumption_number = true;
      } else {
        this.consumption_number = false;
      }
      this.form_data.consumption_amount_end = value.toFixed(2);
    }
  }
}
